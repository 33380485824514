import {
  Box, Button, Container, Text, useColorMode
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import ImgTextModule from "../components/ImgTextModule";
import IntroComponentGreenGray3col from "../components/IntroComponent1";
import IntroComponent2colMonochrome from "../components/IntroComponent2colMonochrome";
import PortfolioTeaser from "../components/PortfolioTeaser";
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation";
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink";

const AboutUs = () => {
  const { setColorMode } = useColorMode();

  useEffect(() => {
    setColorMode("light");
  });

  return (
    <>
      <Helmet>
        <title>About us</title>
      </Helmet>

      <ImgTextModule
        src="header-master.mp4"
        delay={0.3}
        duration={0.5}
        threshold={0.3}
        initialY={10}
        initialX={10}
        video={true}
        h="95vh"
        mediaOpacity={0.4}
        bgColor="brand.black"
      >
        <Container variant="layoutContainer" mt={8}>
          <Box p={8}>
            <FadeInAnimation
              threshold={0.4}
              delay={0.4}
              duration={0.7}
              initialX={0}
              initialY={-10}
            >
              <Text color="white" fontWeight="normal" fontSize="6xl">
                About us.
              </Text>
            </FadeInAnimation>

            <FadeInAnimation
              threshold={0.4}
              delay={1}
              duration={0.7}
              initialX={0}
              initialY={10}
            >
                 <Text color="brand.white" mb={4} maxW={{base:"100%", lg:"50%" }}fontSize="2xl">
               Our investment targets benefit from our competence and experience. 
              </Text>
              <Text color="brand.medgray" mb={24} maxW={{base:"100%", lg:"50%" }}fontSize="lg">
               Sint qui voluptate ad ipsum fugiat tempor aute laborum excepteur ullamco et laborum do dolor.Et aliqua dolore tempor sunt aute anim quis deserunt.
              </Text>
            </FadeInAnimation>
          </Box>
        </Container>
      </ImgTextModule>
      <IntroComponent2colMonochrome
     
        headline="We are focused "
        text1="We not only invest funds. We invest our knowledge. Our connections. Our experience."
        text2="Our investment targets benefit from our competence and experience. Your vision will be supported and helped along the path to success. We are partners."
      />
      <IntroComponentGreenGray3col
        headline="We are commited" 
        text1="Amet commodo reprehenderit aliqua cillum ex exercitation proident."
        text2="Consectetur proident consectetur esse cupidatat nulla sint exercitation ullamco sint laboris et aliqua. Deserunt anim velit sit aliqua enim anim. onsequat Lorem nisi nostrud tempor culpa cillum. Aliquip exercitation eiusmod magna aliqua ipsum qui in et ad aute. Sunt labore proident consequat sunt id consectetur dolore ipsum sunt. Magna ex ex ad cillum est."
        text3="Tempor voluptate ipsum ut enim eu laborum veniam nisi tempor excepteur do duis."
        text4="Excepteur labore consequat sunt adipisicing minim ullamco. Esse sit incididunt velit exercitation qui fugiat amet ipsum irure dolor et enim ex. Dolor ipsum in officia reprehenderit magna aute cillum laboris pariatur Loretat voluptate cupidatat cillum. Deserunt culpa ipsum exercitation ipsum laborum minim."
      />

    {/* CONTAINER QUADFEATURE */}
    <Container
        variant="layoutContainer"
        mt={0.5}
        mb={0.5}
        display="grid"
        gridGap={0.5}
        gridTemplateColumns={{
          lg: "repeat(1, 1fr)",
          md: "repeat(2, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
      >
       
        
        
        <ImgTextModule
          minH="750px"
          w="100%"
          src="426686.mp4"
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={0.5}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                 6 Points of Truth
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                 How we deal with the challenges of blockchain based investmetns.
                </Text>
              </FadeInAnimation>
        
              <RouterLink  isExternal={false} link={'/investors'}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.green"
                color="brand.darkgray"
              >
                Investment Strategy
              </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
      </Container>





      {/* LAYOUT ELEMENNTS PORTFOLIO */ }



      {/* CONTAINER QUADFEATURE */}
      <Container
        variant="layoutContainer"
        mt={0.5}
        mb={0.5}
        display="grid"
        gridGap={0.5}
        gridTemplateColumns={{
          lg: "repeat(2, 1fr)",
          md: "repeat(2, 1fr)",
          sm: "repeat(1, 1fr)",
        }}
      >
       
        
        
        <ImgTextModule
          minH="450px"
          w="100%"
          src="412503.mp4"
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={1}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Investors Relations
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                  Information at your fingertips.
                </Text>
              </FadeInAnimation>
              <RouterLink  isExternal={false} link={'/investorrelations'}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.green"
                color="brand.darkgray"
              >
                Investors Relations
              </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
        
        <ImgTextModule
          minH="450px"
          w="100%"
          src="header-master.mp4"
          delay={0.9}
          duration={1}
          threshold={0.4}
          initialY={0}
          initialX={0}
          video={true}
          mediaOpacity={1}
          bgColor="black"
        >
          <FadeInAnimation threshold={0.4} duration={1}>
            <Box p={8}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text color="white" fontSize="4xl">
                  Experts
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text color="brand.green" fontSize="md">
                  From Seniority to Innovation. 
                </Text>
              </FadeInAnimation>
              <RouterLink  isExternal={false} link={'/team'}>
              <Button
                mt={4}
                variant="solid"
                bg="brand.green"
                color="brand.darkgray"
              >

                  
                Meet the team.
              </Button>
              </RouterLink>
            </Box>
          </FadeInAnimation>
        </ImgTextModule>
        
      </Container>

      <PortfolioTeaser />


    </>
  );
};

export default AboutUs;
